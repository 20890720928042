<template>
  <div class="cookie-box">
    <h1>{{ this.$t("cookiePolicy") }}</h1>
    <div class="cookie-text">
      <h3>{{ this.$t("cookieUse") }}</h3>
      <div>
        <span>（一） Cookie</span>
        <p>
          {{ this.$t("cookieWhy") }}
        </p>
        <p>
          {{ this.$t("cookieFor") }}
        </p>
        <p>{{ this.$t("cookieList") }}</p>

        <table>
          <tr>
            <td>{{ this.$t("cookiePurpose") }}</td>
            <td>{{ this.$t("cookieDescribe") }}</td>
            <td>{{ this.$t("cookieType") }}</td>
          </tr>
          <tr>
            <td>{{ this.$t("cookiePerformance") }}</td>
            <td>{{ this.$t("cookieLogin") }}</td>
            <td>{{ this.$t("cookieDay") }}</td>
          </tr>
          <tr>
            <td>{{ this.$t("cookieCnzz") }}</td>
            <td>{{ this.$t("cookieTime") }}</td>
            <td>{{ this.$t("cookieForever") }}</td>
          </tr>
        </table>

        <span>（二）Do Not Track（请勿追踪）</span>
        <p>
          {{ this.$t("cookieTrack") }}
        </p>
      </div>
      <p>
        <router-link :to="{ path: '/' }">{{ this.$t("backHome") }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.cookie-box {
  box-sizing: content-box;
  width: 50vw;
  margin: 0 auto;
  background-color: antiquewhite;
  border: 1px solid #bfbfbf;
  height: 1000px;
  overflow-y: auto;
}
.cookie-box > h1 {
  background-color: #808080;
  color: #fff;
  padding-left: 1vw;
}
.cookie-box span {
  font-weight: 900;
}
.cookie-text {
  text-align: left;
  padding: 15px;
  line-height: 1.8;
}
.cookie-box h3 {
  text-align: left;
}

table {
  border-spacing: 0;
  width: 100%;
  border: #808080;
}
table > tr > td {
  text-align: center;
  width: 33.333%;
  height: 7vh;
  border: 1px solid #808080;
}
</style>
